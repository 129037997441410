






















































































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from '@/api'

@Component({
    name: "couponList",
    components: {}
})

export default class extends Vue {
    // 表格数据
    tableData: any = [];
    // 加载状态
    loading = false;
    // 总条数
    total = 0
    // 搜索条件
    search: any = {
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 20, //每页数量
        },
    };

    activated() {
        this.searchTable();
    }

    // 搜索 search:true 重置翻页
    searchTable(search = false) {
        this.loading = true;
        if (search) {
            this.search.pagination = {
                pageSize: 20,
                page: 0,
            };
        }
        api
            .post('J27319', this.search)
            .then(res => {
                this.total = res.total;
                this.tableData = res.list;
            }).finally(() =>{
            this.loading = false;
        })
    }

    changePage() {}

    // 设置
    private obj: any = {};
    private checkList = [];
    private switchVal = true;
    private visible = false;
    private showall = false
    private agentType = '0'
    setting(item: any) {
        api
            .post('J27320', {id: item.id})
            .then(res => {
                this.visible = true;
                this.obj = res;
                this.switchVal = item.status == 0 ? true : false;
                this.agentType = res.brokerType == 1 ? '1' : '0'
                this.showall = res.shareEvery == 1 ? true :false
            })



    }
    protected closeSetDialog() {
        this.visible = false;
    }
    protected handleChange(e: any) {
        this.checkList = e;
    }

    protected AGENTOPERATE() {
        // "id": "752042439696322560", //ID
        //     "brokerType": 2, //经纪人分类 0 普通 1超级
        //     "shareEvery": 1, //分享普通优惠劵
        //     "status": 1 //账号状态 0 正常 1冻结
        let obj = {
            id: this.obj.id,
            status: this.switchVal ? 0 : 1,
            shareEvery: this.showall ? 1 : 0,
            brokerType: Number(this.agentType),
            phone: this.obj.phone
        };
        api
            .post('J27321', obj)
            .then(res => {
                    this.$message.success('操作成功')
                    this.visible = false;
                    this.searchTable();
            })
    }

    //跳转至优惠券界面
    protected showCoupon(id: any) {
        this.$router.push({
            path: 'agentDetail',
            query:{id}
        })
    }


    // 日志
    logDialog = false
    logDetail = false
    logTable = []
    logData = {}
    row = {}
    openDesc = false
    logTotal = 0
    logSearch = {
        id: '',
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 10, //每页数量
        }
    }
    showLogDialog(item: any) {
        this.logSearch.id = item.id
        this.logData = item
        api
            .post('J27322', {...this.logSearch})
            .then(res => {
                this.logDialog = true
                this.logTable = res.list
                this.logTotal = res.total
            })
    }

    handleCloseLogDialog() {
        this.logDialog = false
    }
    toLogDetail(row: any) {
        this.logDetail = true
        this.row = row
    }
    CloseLogDetailDialog(){
        this.logDetail = false
    }



    // 代理情况
    linkTo(id: any) {
        // this.$router.push({
        //     path: 'couponAgentD',
        //     query:{
        //         id:id
        //     }
        // })
    }

    // 领用
    jumpTo(path: any, item: any) {
        // sessionStorage.setItem('coupon_id', item.id)
        // this.$router.push({
        //     path: path
        // })
    }

}
